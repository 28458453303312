.App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #f4f4f9;
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: 10px;
}

.marker {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
  transform: translate(-50%, -50%);
  z-index: 10;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

input[type="text"], .react-datepicker-wrapper, .comments {
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 5px;
}

.date-picker {
  margin-top: 10px;
}

.comments {
  margin-top: 10px;
  height: 80px; /* Adjust height as needed */
}
